import { connect } from 'react-redux';

import MonthlyTargetsOverwriter from '../../Components/Targets/MonthlyTargetsOverwriter';
import { overwriteTarget } from '../../reducers/targets';
import WithDefaults from '../WithDefaults';
import { setAuth } from '../../reducers/auth';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  updateLoading: state.targets.overwrite_loading,
  overwriteMode: state.targets.overwriteMode,
  overwriteSelectedMonth: state.targets.overwriteSelectedMonth,
  monthlyTargetsDateFrom: state.targets.monthlyTargetsDateFrom,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  overwriteTarget(token, targetUuids, targetValue) {
    return overwriteTarget(dispatch, token, targetUuids, targetValue);
  },
  setAuth(...args) {
    return setAuth(dispatch, ...args);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithDefaults(MonthlyTargetsOverwriter));
