import { connect } from 'react-redux';

import Targets from '../../Components/Targets';
import {getAllTargets, initTargetFilters, setFilter, toggleLoading, toggleOverwriteMode, setOverwriteSelectedMonth} from '../../reducers/targets';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  targets: state.targets.data,
  targetFilters: state.targets.filter_options,
  activeFilters: state.targets.activeFilters,
  isLoading: state.targets.loading,
  overwriteMode: state.targets.overwriteMode,
  overwriteSelectedMonth: state.targets.overwriteSelectedMonth,
  monthlyTargetsDateFrom: state.targets.monthlyTargetsDateFrom,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getAllTargets(...args) {
    return getAllTargets(dispatch, ...args);
  },
  initTargetFilter(...args) {
    return initTargetFilters(dispatch, ...args);
  },
  setFilter(...args) {
    return setFilter(dispatch, ...args);
  },
  toggleLoading(...args) {
    return toggleLoading(dispatch, ...args);
  },
  toggleOverwriteMode(...args) {
    return toggleOverwriteMode(dispatch, ...args);
  },
  setOverwriteSelectedMonth(...args) {
    return setOverwriteSelectedMonth(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(Targets));
