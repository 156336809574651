import React from 'react';
import { Row, Col, Button, Divider } from 'antd';
import {
  AreaChartOutlined,
  DoubleLeftOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TargetsFilter from './TargetsFilter';
import TargetsTable from '../../Containers/TargetsTable';
import Config from '../../Config';

class Targets extends React.PureComponent {
  componentDidMount() {
    this.initTargetFilters();
    this.getAllTargets();
  }

  async getAllTargets() {
    const { getAllTargets, getIdToken, activeFilters } = this.props;
    const token = await getIdToken();
    await getAllTargets(token, activeFilters);
  }

  async initTargetFilters() {
    const { initTargetFilter } = this.props;
    const { getIdToken } = this.props;
    const token = await getIdToken();
    await initTargetFilter(token);
  }

  render() {
    const {
      overwriteMode,
      toggleOverwriteMode,
      t,
      targetFilters,
      setFilter,
      isLoading,
      activeFilters,
    } = this.props;

    return [
      <Row type="flex" justify="center" align="top">
        <Col span={4}>
          <h1>
            <strong>{t('common:target')}</strong>
          </h1>
          <Divider />
        </Col>
        <Col span={20} style={{ textAlign: 'right' }}>
          {overwriteMode ? (
            <Button
              onClick={() => toggleOverwriteMode()}
              ghost
              className="overwrite-target-button"
              style={{ backgroundColor: '#fff' }}
              type="danger"
              icon={<DoubleLeftOutlined />}
            >
              {t('common:cancelEditing')}
            </Button>
          ) : (
            [
              <a
                href={Config.apiGateway.QS_DASHBOARD_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button icon={<AreaChartOutlined />}>
                  {t('common:viewTargetsReportingDaskboard')}
                </Button>
              </a>,
              <Button
                onClick={() => toggleOverwriteMode()}
                ghost
                className="overwrite-target-button"
                style={{ backgroundColor: '#fff' }}
                type="danger"
                icon={<LockOutlined />}
              >
                {t('common:editLockedTargets')}
              </Button>,
            ]
          )}
          <Divider />
        </Col>
      </Row>,
      <TargetsFilter
        filterOptions={targetFilters}
        setFilter={setFilter}
        isLoading={isLoading}
        activeFilters={activeFilters}
        getAllTargets={() => this.getAllTargets()}
      />,
      <TargetsTable />,
    ];
  }
}

Targets.propTypes = {
  getAllTargets: PropTypes.any.isRequired,
  getIdToken: PropTypes.any.isRequired,
  activeFilters: PropTypes.any.isRequired,
  overwriteMode: PropTypes.any.isRequired,
  toggleOverwriteMode: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
  targetFilters: PropTypes.any.isRequired,
  setFilter: PropTypes.any.isRequired,
  isLoading: PropTypes.any.isRequired,
  initTargetFilter: PropTypes.any.isRequired,
};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(
  Targets,
);
