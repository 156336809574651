import { connect } from 'react-redux';

import TargetsUpdater from '../../Components/Targets/TargetsUpdater';
import { updateTarget } from '../../reducers/targets';
import WithDefaults from '../WithDefaults';
import { setAuth } from '../../reducers/auth';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  updateLoading: state.targets.updateLoading,
  activeFilters: state.targets.activeFilters,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  updateTarget(token, activeFilters, targetUuids, targetValue) {
    return updateTarget(
      dispatch,
      token,
      activeFilters,
      targetUuids,
      targetValue,
    );
  },
  setAuth(...args) {
    return setAuth(dispatch, ...args);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithDefaults(TargetsUpdater));
