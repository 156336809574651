import { combineReducers } from 'redux';
import appConfig from './appConfig';
import auth from './auth';
import targets from './targets';

const appReducer = combineReducers({
  appConfig,
  auth,
  targets,
});

export default (state, action) => appReducer(state, action);
