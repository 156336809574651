import { connect } from 'react-redux';

import TargetsTable from '../../Components/Targets/TargetsTable';
import { getAllTargets, setOverwriteSelectedMonth} from '../../reducers/targets';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  targets: state.targets.data,
  pagination: state.targets.pagination,
  isLoading: state.targets.loading,
  overwriteMode: state.targets.overwriteMode,
  overwriteSelectedMonth: state.targets.overwriteSelectedMonth,
  monthlyTargetsDateFrom: state.targets.monthlyTargetsDateFrom,
  riskProfileAccess: state.auth.permission.teams.access,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  setOverwriteSelectedMonth(...args) {
    return setOverwriteSelectedMonth(dispatch, ...args);
  },
  getAllTargets(...args) {
    return getAllTargets(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(TargetsTable));
