import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { MaintenanceMode, Exception, intercomMessenger } from 'componentlibrary';
import { createBrowserHistory } from 'history';
import { routesWithPermission } from '../../routes';
import Config from '../../Config';

const defaultHistory = createBrowserHistory();

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshKey: null
    };
  }

  componentDidMount() {
    this.setAuth();
    this.getAppConfig();
  }

  componentDidUpdate() {
    const { context, i18n } = this.props;
    const { refreshKey } = this.state;

    if (context.refreshKey !== refreshKey) {
      this.refresh(context.refreshKey);
    }
    if (i18n && context.currentLanguage !== i18n.language) {
      i18n.changeLanguage(context.currentLanguage);
    }
  }

  get permissionDenied() {
    const { t } = this.props;

    return (
      <Exception
        status={403}
        message={t('errors:403')}
      />
    );
  }

  async getAppConfig() {
    const { getAppConfig } = this.props;
    await getAppConfig();
    this.appConfigInterval = setInterval(getAppConfig.bind(this), 60 * 1000);
  }

  setAuth() {
    const { context, setAuth } = this.props;
    setAuth(context.getIdToken, context.permission);
  }

  refresh(refreshKey) {
    const { history } = this.props;
    history.push('/targets');

    this.setState({
      refreshKey
    });
  }

  render() {
    const {
      t, appConfig, showAuthConfirm, i18n, auth = {}, context, history
    } = this.props;

    if (!appConfig) {
      return null;
    }

    if (appConfig && appConfig.maintenanceMode) {
      return (
        <MaintenanceMode
          title={t('maintenanceMode:serviceDown')}
          description={t('maintenanceMode:pleaseTryAgain')}
        />
      );
    }

    const intercomPayload = auth && auth.idToken ? { user_id: auth.idToken['custom:forwood_uuid'], user_hash: auth.idToken.hmac } : {};

    return (
      <>
        <Router history={history || defaultHistory}>
          <div style={{ margin: '20px' }}>
            {renderRoutes(
              routesWithPermission(context.permission, this.permissionDenied),
            )}
          </div>
        </Router>
        {/* {!showAuthConfirm && */}
        {/*   appConfig.intercomEnabled && */}
        {/*   intercomMessenger( */}
        {/*     Config.reactApp.HOSTNAME, */}
        {/*     i18n.language, */}
        {/*     intercomPayload, */}
        {/*   )} */}
      </>
    );
  }
}

App.defaultProps = {
  auth: {},
  appConfig: null,
  showAuthConfirm: false
};

App.propTypes = {
  auth: PropTypes.object,
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  getAppConfig: PropTypes.func.isRequired,
  appConfig: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  setAuth: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showAuthConfirm: PropTypes.bool,
};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(App);
