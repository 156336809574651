import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row, Select, Card } from 'antd';
import {
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

export class TargetsFilter extends Component {
  generateSelect(selectConfig, withOffset) {
    const { fieldName } = selectConfig;
    const {
      filterOptions,
      setFilter,
      activeFilters,
      getAllTargets,
      isLoading,
    } = this.props;

    const options = Object.keys(filterOptions[fieldName]).sort((a, b) =>
      filterOptions[fieldName][a].localeCompare(filterOptions[fieldName][b]),
    );

    const filterOption = (input, option) => {
      let str = option ? option.label : '';
      if (!str) str = '';
      return str.includes(input);
    };

    return (
      <Col md={4} xs={24} offset={+withOffset} className="search">
        {React.cloneElement(selectConfig.icon, {
          style: { color: '#1168e3', marginRight: 3 },
        })}
        <span className="team-label">{selectConfig.label}:</span>
        <Select
          label={selectConfig.label}
          loading={isLoading}
          defaultValue={activeFilters[fieldName][0]}
          disabled={isLoading}
          placeholder={`Select a ${selectConfig.label}`}
          dropdownMatchSelectWidth={false}
          style={{ width: '100%' }}
          showSearch
          filterOption={filterOption}
          onChange={(selectedOption) => {
            setFilter(fieldName, selectedOption, activeFilters);
            getAllTargets();
          }}
          options={[
            { key: 'all', value: 'all', label: 'Select all' },
            ...options.map((opt) => ({
              key: opt,
              value: opt,
              label: filterOptions[fieldName][opt],
            })),
          ]}
        />
      </Col>
    );
  }

  render() {
    const { t } = this.props;

    const filterSelectConfig = [
      {
        label: t('common:team'),
        icon: <TeamOutlined />,
        fieldName: 'team_uuid',
      },
      {
        label: t('common:teamMemberName'),
        icon: <UserOutlined />,
        fieldName: 'team_member_uuid',
      },
      {
        label: t('common:teamLeaderName'),
        icon: <UserOutlined />,
        fieldName: 'team_leader_uuid',
      },
      {
        label: t('common:task'),
        icon: <SolutionOutlined />,
        fieldName: 'task_uuid',
      },
      {
        label: t('common:criticalRisk'),
        icon: <WarningOutlined />,
        fieldName: 'critical_risk_uuid',
      },
    ];

    return (
      <Card style={{ marginTop: 10, marginBottom: 15 }}>
        <h3 className="filter-by-title">{t('common:filterBy')}</h3>
        <Row>
          {filterSelectConfig.map((selectConfig, index) =>
            this.generateSelect(selectConfig, index !== 0),
          )}
        </Row>
      </Card>
    );
  }
}

export default withTranslation(['common', 'errors', 'maintenanceMode'])(
  TargetsFilter,
);

TargetsFilter.propTypes = {
  t: PropTypes.any.isRequired,
  filterOptions: PropTypes.any.isRequired,
  setFilter: PropTypes.any.isRequired,
  activeFilters: PropTypes.any.isRequired,
  getAllTargets: PropTypes.any.isRequired,
  isLoading: PropTypes.any.isRequired,
};
