import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tag, Button } from 'antd';
import { MinusOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';

export class TargetsUpdater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialTarget: props.target.target_month_default_value,
      currentTarget: props.target.target_month_default_value,
    };
  }

  updateCurrentTarget(newTarget) {
    this.setState({ currentTarget: newTarget });
  }

  async sendUpdateRequest(targetUuids, newTarget) {
    const { getIdToken, updateTarget, activeFilters } = this.props;
    const token = await getIdToken();
    const updateResult = await updateTarget(
      token,
      activeFilters,
      targetUuids,
      newTarget,
    );
    if (updateResult) {
      this.setState({ initialTarget: newTarget });
    }
  }

  render() {
    const { target, updateLoading } = this.props;
    const { currentTarget, initialTarget } = this.state;

    if ('children' in target) {
      let totalTargets = 0;
      target.children.map((singleTarget) => {
        totalTargets += singleTarget.target_month_default_value;
        return null;
      });
      return <Tag color="#385bdc">{totalTargets}</Tag>;
    }

    const getTagColor = (current, initial) => {
      if (current > initial) return '#5ba65c';
      if (current < initial) return '#c45c5c';
      return '#7a91e0';
    };

    const tagColor = getTagColor(currentTarget, initialTarget);

    return [
      <Tag color={tagColor}>{currentTarget}</Tag>,
      <Button
        className="target-update-minus-button"
        disabled={currentTarget === 0 || updateLoading === target.target_uuid}
        icon={<MinusOutlined />}
        size="small"
        onClick={() => this.updateCurrentTarget(currentTarget - 1)}
      />,
      <Button
        icon={<PlusOutlined />}
        size="small"
        disabled={updateLoading === target.target_uuid}
        onClick={() => this.updateCurrentTarget(currentTarget + 1)}
      />,
      initialTarget !== currentTarget ? (
        <Button
          className="target-save-button"
          ghost
          loading={
            Array.isArray(updateLoading) &&
            updateLoading.filter((item) => target.target_uuids.includes(item))
              .length > 0
          }
          onClick={() =>
            this.sendUpdateRequest(target.target_uuids, currentTarget)
          }
          type="primary"
          icon={<SaveOutlined />}
          size="small"
        >
          Save
        </Button>
      ) : (
        ''
      ),
    ];
  }
}

TargetsUpdater.defaultProps = {};

TargetsUpdater.propTypes = {
  target: PropTypes.any.isRequired,
  getIdToken: PropTypes.any.isRequired,
  updateTarget: PropTypes.any.isRequired,
  activeFilters: PropTypes.any.isRequired,
  updateLoading: PropTypes.any.isRequired,
};

export default TargetsUpdater;
