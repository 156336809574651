import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Table, Popover, Tag, Button } from 'antd';
import {
  EnterOutlined,
  SolutionOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import TargetsUpdater from '../../../Containers/TargetsUpdater';
import MonthlyTargetsOverwriter from '../../../Containers/MonthlyTargetsOverwriter';
import Config from '../../../Config';

export class TargetsTable extends Component {
  async handlePaginationChange(page, size) {
    const { getAllTargets, getIdToken, activeFilters } = this.props;
    const token = await getIdToken();
    getAllTargets(token, {
      ...activeFilters,
      'page.number': page,
      'page.size': size,
    });
  }

  render() {
    const {
      monthlyTargetsDateFrom,
      setOverwriteSelectedMonth,
      overwriteMode,
      overwriteSelectedMonth,
      isLoading,
      targets,
      riskProfileAccess,
      pagination,
      history,
      t,
    } = this.props;

    const todayByClientTimezone = moment()
      .tz(Config.apiGateway.CLIENT_TIMEZONE)
      .format('D');
    const targetColumnHeaderMonth =
      todayByClientTimezone > 6
        ? moment().add(1, 'months').format('MMM')
        : moment().format('MMM');
    const monthsDifferentFromToday = Math.ceil(
      moment(monthlyTargetsDateFrom).diff(moment(), 'months', true),
    );
    const monthlyTargetsFilterOptions = [];

    const showRiskProfile = riskProfileAccess && !overwriteMode;

    // calculate months between earliest and latest target
    for (let i = Math.abs(monthsDifferentFromToday); i > 0; i--) {
      const momentObj = moment().startOf('month').subtract(i, 'months');
      monthlyTargetsFilterOptions.push(
        <Select.Option key={momentObj.format('YYYY-MM')}>
          {momentObj.format('MMMM')}{' '}
          <span style={{ color: '#b2b2b2', fontSize: '11px' }}>
            ({momentObj.format('YYYY')})
          </span>
        </Select.Option>,
      );
    }

    monthlyTargetsFilterOptions.push(
      <Select.Option
        key={moment().format('YYYY-MM')}
        value={moment().format('YYYY-MM')}
      >
        {moment().format('MMMM')}
        <span style={{ color: '#b2b2b2', fontSize: '11px' }}>
          ({moment().format('YYYY')})
        </span>
      </Select.Option>,
    );

    const overwriteMonthSelect = [
      <span style={{ float: 'left' }}>{t('common:supervisorTarget')}</span>,
      <Select
        size="small"
        style={{
          float: 'right',
          position: 'absolute',
          right: 0,
          top: '15px',
        }}
        onChange={setOverwriteSelectedMonth}
        dropdownMatchSelectWidth={false}
        initialValue={overwriteSelectedMonth}
        defaultValue={overwriteSelectedMonth}
      >
        {monthlyTargetsFilterOptions.reverse()}
      </Select>,
    ];

    const renderCriticalRisk = (text, record) => {
      if (record.is_task) {
        const risks = Object.keys(record.critical_risks);

        return (
          <Popover
            content={risks.map((risk) => (
              <Tag color="orange">{risk}</Tag>
            ))}
          >
            <Tag color="orange">
              <WarningOutlined /> {risks.length} {t('common:risks')}
            </Tag>
          </Popover>
        );
      }

      if ('children' in record) {
        return <Tag color="orange">{text}</Tag>;
      }

      return null;
    };

    const renderRiskProfile = (_, record) => {
      if (!showRiskProfile || !('children' in record)) return null;

      const handleClick = (e) => {
        e.preventDefault();
        history.push(
          `/teams/${record.team_uuid}/risk-profile?referrer=targets`,
        );
      };

      return (
        <div>
          <Button onClick={handleClick}>
            <SolutionOutlined />
          </Button>
        </div>
      );
    };

    const tableColumnDefinition = [
      {
        title: t('common:team'),
        dataIndex: 'team_name',
        key: 'team_name',
        width: '15%',
        render: (text, record) =>
          !('children' in record) ? (
            <span>
              <EnterOutlined
                style={{ transform: 'rotateY(180deg) scale(1)' }}
              />{' '}
              {text}
            </span>
          ) : (
            text
          ),
      },
      {
        title: t('common:teamLeader'),
        dataIndex: 'team_leader_name',
        key: 'team_leader_name',
        width: '15%',
        render: (text, record) => (
          <span>
            <UserOutlined /> {text || record.team_leader_uuid}
          </span>
        ),
      },
      {
        title: t('common:task'),
        dataIndex: 'task',
        key: 'task',
        width: '15%',
        render: (text, record) => (record.is_task ? <span>{text}</span> : null),
      },
      {
        title: t('common:criticalRisk'),
        dataIndex: 'critical_risk',
        key: 'critical_risk',
        width: '15%',
        render: renderCriticalRisk,
      },
      {
        title: t('common:teamMember'),
        dataIndex: 'team_member_name',
        key: 'team_member_name',
        width: '15%',
        render: (text, record) =>
          'children' in record ? (
            <Tag color="gray">
              <UserOutlined /> {record.children.length}{' '}
              {t('common:teamMembers')}
            </Tag>
          ) : (
            <span>
              <UserOutlined /> {text}
            </span>
          ),
      },
      {
        title: overwriteMode ? (
          overwriteMonthSelect
        ) : (
          <Popover
            placement="topRight"
            content={t('common:supervisorTargetTooltip')}
          >
            <span>
              {t('common:supervisorTarget')}{' '}
              <Tag color="#108ee9">{targetColumnHeaderMonth}</Tag>
            </span>
          </Popover>
        ),
        dataIndex: 'target_month_default_value',
        key: 'target_month_default_value',
        render: (text, record) =>
          overwriteMode ? (
            <MonthlyTargetsOverwriter target={record} />
          ) : (
            <TargetsUpdater target={record} />
          ),
        width: !overwriteMode ? '15%' : '20%',
      },
      {
        title: showRiskProfile ? t('common:riskProfile') : '',
        key: 'risk_profile',
        width: '8%',
        render: renderRiskProfile,
      },
    ];

    return (
      <Table
        className={['responsive-table', overwriteMode ? 'overwrite' : '']}
        dataSource={[...targets].sort((a, b) =>
          a.team_name.localeCompare(b.team_name),
        )}
        columns={tableColumnDefinition}
        rowKey="key"
        loading={isLoading}
        pagination={{
          position: 'bottom',
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          onChange: (page, pageSize) =>
            this.handlePaginationChange(page, pageSize),
          onShowSizeChange: (page, pageSize) =>
            this.handlePaginationChange(page, pageSize),
          ...pagination,
        }}
        expandRowByClick
        expandable={{
          rowExpandable: true,
        }}
      />
    );
  }
}

TargetsTable.defaultProps = {
  targets: [],
  overwriteMode: false,
};

TargetsTable.propTypes = {
  targets: PropTypes.array,
  overwriteMode: PropTypes.bool,
  riskProfileAccess: PropTypes.bool.isRequired,
  getAllTargets: PropTypes.any.isRequired,
  getIdToken: PropTypes.any.isRequired,
  activeFilters: PropTypes.any.isRequired,
  monthlyTargetsDateFrom: PropTypes.any.isRequired,
  setOverwriteSelectedMonth: PropTypes.any.isRequired,
  overwriteSelectedMonth: PropTypes.any.isRequired,
  isLoading: PropTypes.any.isRequired,
  pagination: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

export default withRouter(
  withTranslation(['common', 'errors', 'maintenanceMode'])(TargetsTable),
);
