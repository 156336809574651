import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tag, Button } from 'antd';
import moment from 'moment-timezone';
import { MinusOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';

const MIN_TARGET_VALUE = 0;

export class MonthlyTargetsOverwriter extends Component {
  static monthlyTargetDateObject(monthlyTarget) {
    const yearMonthDateObject = new Date();
    const parsedMonth =
      parseInt(
        String(monthlyTarget.attributes.target_month).replace('0', ''),
        10,
      ) - 1;

    yearMonthDateObject.setDate(1);
    yearMonthDateObject.setMonth(parsedMonth);
    yearMonthDateObject.setYear(
      parseInt(monthlyTarget.attributes.target_year, 10),
    );

    return yearMonthDateObject;
  }

  constructor(props) {
    super(props);

    if (!('children' in props.target)) return;

    this.state = {
      initialTarget: MIN_TARGET_VALUE,
      currentTarget: MIN_TARGET_VALUE,
    };
  }

  updateCurrentTarget(newTarget) {
    this.setState({ currentTarget: newTarget });
  }

  async sendUpdateRequest(targetUuids, newTarget) {
    const { getIdToken, overwriteTarget } = this.props;
    const token = await getIdToken();
    const updateSuccessful = await overwriteTarget(
      token,
      targetUuids,
      newTarget,
    );

    if (updateSuccessful) {
      this.setState({ initialTarget: newTarget });
    }
  }

  render() {
    const { target, updateLoading, overwriteSelectedMonth } = this.props;
    const { initialTarget, currentTarget } = this.state;

    if ('children' in target) {
      let totalTargets = 0;

      target.children.map((singleTarget) => {
        if (Array.isArray(singleTarget.monthly_targets.data)) {
          const selectedMonthlyTarget =
            singleTarget.monthly_targets.data.filter((monthlyTarget) => {
              const yearMonthDateObject =
                this.monthlyTargetDateObject(monthlyTarget);
              return (
                overwriteSelectedMonth ===
                moment(yearMonthDateObject).format('YYYY-MM')
              );
            });

          if (selectedMonthlyTarget.length > 0) {
            totalTargets += selectedMonthlyTarget[0].attributes.target_value;
          }
        }

        return null;
      });

      return (
        <Tag color={totalTargets === 0 ? '#9d9d9d' : '#385bdc'}>
          {totalTargets === 0 ? '-' : totalTargets}
        </Tag>
      );
    }

    const targetsWithinSelectedMonth = target.monthly_targets.filter(
      (monthlyTarget) => {
        const yearMonthDateObject = this.monthlyTargetDateObject(monthlyTarget);

        return (
          overwriteSelectedMonth ===
          moment(yearMonthDateObject).format('YYYY-MM')
        );
      },
    );

    if (targetsWithinSelectedMonth.length === 0) {
      return <Tag color="#9d9d9d">{' - '}</Tag>;
    }

    if (
      initialTarget !== targetsWithinSelectedMonth[0].attributes.target_value
    ) {
      this.setState({
        currentTarget: targetsWithinSelectedMonth[0].attributes.target_value,
        initialTarget: targetsWithinSelectedMonth[0].attributes.target_value,
      });
    }

    const getTagColor = (current, initial) => {
      if (current > initial) return '#5ba65c';
      if (current < initial) return '#c45c5c';
      return '#7a91e0';
    };
    const tagColor = getTagColor(currentTarget, initialTarget);

    return [
      <Tag color={tagColor}>{currentTarget}</Tag>,
      <Button
        icon={<MinusOutlined />}
        size="small"
        disabled={
          updateLoading === targetsWithinSelectedMonth[0].id ||
          currentTarget === MIN_TARGET_VALUE
        }
        onClick={() => this.updateCurrentTarget(currentTarget - 1)}
      />,
      <Button
        icon=<PlusOutlined />
        size="small"
        disabled={updateLoading === targetsWithinSelectedMonth[0].id}
        onClick={() => this.updateCurrentTarget(currentTarget + 1)}
      />,
      initialTarget !== currentTarget ? (
        <Button
          className="target-save-button"
          ghost
          loading={
            Array.isArray(updateLoading) &&
            updateLoading.filter((item) =>
              [targetsWithinSelectedMonth[0].id].includes(item),
            ).length > 0
          }
          onClick={() =>
            this.sendUpdateRequest(
              [targetsWithinSelectedMonth[0].id],
              currentTarget,
            )
          }
          type="danger"
          style={{ backgroundColor: '#fff !important' }}
          icon={<SaveOutlined />}
          size="small"
        >
          Overwrite
        </Button>
      ) : (
        ''
      ),
    ];
  }
}

MonthlyTargetsOverwriter.defaultProps = {};

MonthlyTargetsOverwriter.propTypes = {
  target: PropTypes.object.isRequired,
  getIdToken: PropTypes.any.isRequired,
  overwriteTarget: PropTypes.any.isRequired,
  updateLoading: PropTypes.any.isRequired,
  overwriteSelectedMonth: PropTypes.any.isRequired,
};

export default MonthlyTargetsOverwriter;
